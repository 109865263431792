import img1 from "../../assets/sponsorImage/Group 3.png"
import img2 from "../../assets/sponsorImage/Group 4.png"
import img3 from "../../assets/sponsorImage/Group 11.png"
import img4 from "../../assets/sponsorImage/Group 12.png"
import img5 from "../../assets/sponsorImage/Group 13.png"
import img6 from "../../assets/sponsorImage/Group 17.png"
import img7 from "../../assets/sponsorImage/Group 18.png"
import img8 from "../../assets/sponsorImage/Group 19.png"
import img9 from "../../assets/sponsorImage/Group 23.png"
import img10 from "../../assets/sponsorImage/Group 24.png"
import img11 from "../../assets/sponsorImage/Group 25.png"
import img12 from "../../assets/sponsorImage/Group 26.png"
import img13 from "../../assets/sponsorImage/Group 27.png"
import img14 from "../../assets/sponsorImage/Group 28.png"
import img15 from "../../assets/sponsorImage/Group 29.png"
import img16 from "../../assets/sponsorImage/Group 30.png"
import img17 from "../../assets/sponsorImage/Group 31.jpg"
import img18 from "../../assets/sponsorImage/Group 32.png"
import img19 from "../../assets/sponsorImage/Group 33.jpg"
import img20 from "../../assets/sponsorImage/Group 34.jpg"
import img21 from "../../assets/sponsorImage/Group 36.png"
import img22 from "../../assets/sponsorImage/Group 37.png"
import img23 from "../../assets/sponsorImage/Group 38.jpg"



const sliderData = [
    {
        id:"1",
        img:img1,
        title:"",
        discription:"",
    },
    {
        id:"2",
        img:img2,
        title:"",
        discription:"",
    },
    {
        id:"3",
        img:img3,
        title:"",
        discription:"",
    },
    {
        id:"4",
        img:img4,
        title:"",
        discription:"",
    },
    {
        id:"5",
        img:img5,
        title:"",
        discription:"",
    },
    {
        id:"6",
        img:img6,
        title:"",
        discription:"",
    },
    {
        id:"7",
        img:img7
    },
    {
        id:"8",
        img:img8
    },
    {
        id:"9",
        img:img9
    },

    {
        id:"10",
        img:img10
    },
    {
        id:"11",
        img:img11
    },
    {
        id:"12",
        img:img12
    },

    {
        id:"13",
        img:img13
    },
    {
        id:"14",
        img:img14
    },
    {
        id:"15",
        img:img15
    },
    {
        id:"16",
        img:img16
    },

    {
        id:"17",
        img:img17
    },
    {
        id:"18",
        img:img18
    },

    {
        id:"19",
        img:img19
    },
    {
        id:"20",
        img:img20
    },
    {
        id:"21",
        img:img21
    },
    {
        id:"22",
        img:img22
    },
    {
        id:"23",
        img:img23
    },

]

export default sliderData;