export const GalleryMedia =[
    {url: require('./DSC_0433.JPG')},
    {url: require('./DSC_0441.JPG')},
    {url: require('./DSC_0459.JPG')},
    {url: require('./DSC_0477.JPG')},
    {url: require('./DSC_0544.JPG')},
    {url: require('./DSC_0836.JPG')},
    {url: require('./DSC_0661.JPG')},
    {url: require('./DSC_0655.JPG')},
    {url: require('./DSC_0580.JPG')},
    {url: require('./DSC_0596.JPG')},
    {url: require('./DSC_0528.JPG')},
]